const CryptoPayments = () => {
  return (
    <form>
      <span>Crypto Payments Coming Soon</span>
      <div className="form-group mt-10">
        <input type="submit" className="custom-button" value="make payment" />
      </div>
    </form>
  );
};

export default CryptoPayments;
