const Hero = () => {
  return (
    <section
      className="main-page-header speaker-banner bg_img"
      data-background="/images/banner/banner07.jpg"
    >
      <div className="container">
        <div className="speaker-banner-content">
          <h2 className="title">about us</h2>
          <ul className="breadcrumb">
            <li>
              <a href="index.html"> Home </a>
            </li>
            <li>about us</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Hero;
